export const typography = {
  h1: {
    fontSize: '2.25rem',
  },
  h2: {
    fontSize: '2rem',
  },
  h3: {
    fontSize: '1.625rem',
  },
  h4: {
    fontSize: '1.45rem',
  },

  title1: {
    fontSize: '1.25rem',
  },
  title2: {
    fontSize: '1.125rem',
  },

  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  body3: {
    fontSize: '0.75rem',
  },

  eyebrow: {
    fontSize: '0.625rem',
  },
};
